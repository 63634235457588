input {
  outline: none;
}

.container {
  min-height: 100vh;
  background: #333;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container form {
  max-width: 600px;
  width: 600px;
  min-height: 200px;
  background: rgba(233, 215, 215, 0.2);
  padding: 20px;
}
.container form h1 {
  text-align: center;
  margin-bottom: 20px;
}
.container form .field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.container form .field input {
  height: 40px;
  padding: 0 10px;
}
.container form button {
  height: 50px;
  width: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.container .items {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.container .items .item {
  background: white;
  color: #333;
  padding: 5px 10px;
  margin-right: 5px;
}
.container .items .item_action button {
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  cursor: pointer;
}
.container .items .item_action button:hover {
  transform: scale(0.9);
}
.container .items .item_action button:nth-child(2) {
  background: blue;
  color: white;
}
.container .items .item_action button:nth-child(3) {
  background: rgb(181, 18, 0);
  color: white;
}

input {
  height: 40px;
}

.foods_cotainer {
  min-height: 100vh;
  background: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.foods_cotainer h2 {
  text-align: center;
}
.foods_cotainer form {
  min-height: 200px;
  width: 400px;
  max-width: 400px;
  background: rgb(80, 80, 80);
  padding: 5px 10px;
}
.foods_cotainer form .form_group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.foods_cotainer form .form_group input {
  padding: 0 10px;
}
.foods_cotainer form button {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  cursor: pointer;
}
.food_collection {
  width: 400px;
  max-width: 400px;
  background: rgb(80, 80, 80);
  padding: 5px 10px;
  margin-bottom: 30px;
}
.food_collection .collection_group {
  background: #333;
  margin-bottom: 10px;
  min-height: 50px;
  padding: 10px;
}
.food_collection .collection_group .content_collection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.food_collection .collection_group .content_collection h2 {
  width: 100%;
  height: 100%;
  background: rebeccapurple;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.food_collection .collection_group .content_collection p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rebeccapurple;
}
.food_collection .collection_group .action_collection {
  display: flex;
  justify-content: center;
}
.food_collection .collection_group .action_collection button {
  padding: 5px 10px;
  margin: 10px 5px;
  cursor: pointer;
}

.update_form {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 300px;

  background: rebeccapurple;
  padding: 5px;
}
.update_form input {
  width: 100%;
}
.update_form .action {
  display: flex;
  justify-content: center;
}
.update_form .action button {
  padding: 5px 10px;
  cursor: pointer;
}
.update_form_in {
  display: unset;
}
